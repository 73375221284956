import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './core/helpers/auth/auth-guard.helper';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './components/home/home.module#HomeModule',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuardService],
  },
  {
    path: 'apresentacao',
    loadChildren: './components/apresentacao/apresentacao.module#ApresentacaoModule',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuardService],
  },
  {
    path: 'meggasolar',
    loadChildren: './components/about/about.module#AboutModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'sistema-meggasolar',
    loadChildren: './components/sistema-meggasolar/sistema-meggasolar.module#SistemaMeggaSolarModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'energia-fotovoltaica',
    loadChildren: './components/fotovoltaica/fotovoltaica.module#FotovoltaicaModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'projetos',
    loadChildren: './components/projetos/projetos.module#ProjetosModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'ondeatuamos',
    loadChildren: './components/atuacao-orcamento/atuacao-orcamento.module#AtuacaoOrcamentoModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'contato',
    loadChildren: './components/contato/contato.module#ContatoModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'contato-parceiro',
    loadChildren: './components/contato-parceiros/contato-parceiros.module#ContatoParceirosModule',
    runGuardsAndResolvers: 'always'
  },

  {
    path: 'contato-orcamento',
    loadChildren: './components/contato-orcamento/contato-orcamento.module#ContatoOrcamentoModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'novidades',
    loadChildren: './components/novidades/novidades.module#NovidadesModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'servicos',
    loadChildren: './components/servicos/servicos.module#ServicosModule',
    runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false, anchorScrolling: "enabled"}
    )
  ],
  exports: [RouterModule],
  providers: [
    AuthGuardService
  ]
})

export class AppRoutingModules {
}
